import { useEffect, useState } from 'react';
import axios from 'axios';

const usePrayerTimesPresentation = (organizationId, prayerTimesId) => {
  const [organizationData, setOrganizationData] = useState({
    img_dark: null,
    img_light: null
  });
  const [title, setTitle] = useState(null);
  const [prayerTimes, setPrayerTimes] = useState(null);
  const [pages, setPages] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchPrayerTimes = async date => {
    date = date ?? new Date(); // Ensure date is a Date object, if not, create a new Date object
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // getMonth() returns 0-11, padStart adds leading zero if needed
    const day = String(date.getDate()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day}`;

    setLoading(true);

    try {
      const response = await axios.get(
        `/ms/prayertime/${organizationId}/${prayerTimesId}/${formattedDate}`
      );
      //setPrayerData(response.data.result);
      setTitle(response.data.result.title);
      setPrayerTimes(response.data.result.timings);
      setPages(response.data.result.pages);
      setOrganizationData(obj => {
        return {
          ...obj,
          img_light: response.data.result.organization.organizationLogoLight,
          img_dark: response.data.result.organization.organizationLogoDark
        };
      });
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPrayerTimes();
  }, []);

  return {
    prayerTimes,
    pages,
    organizationData,
    title,
    loading,
    error,
    fetchPrayerTimes
  };
};

export default usePrayerTimesPresentation;
