import { Card, Col, Image, Row } from 'react-bootstrap';
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import AppContext from '../../../../../context/Context';

const ImageRightTextLeft = ({ page }) => {
  let {
    config: { isDark }
  } = useContext(AppContext);

  return (
    <Row className={'justify-content-center mb-3'}>
      <Col xs={12}>
        <Card
          className=" d-flex "
          style={{ height: '80vh', overflow: 'hidden' }}
        >
          <Card.Body
            className="d-flex p-3 justify-content-center"
            style={{ maxHeight: '80vh' }}
          >
            <Row
              className={'justify-content-center align-items-center mt-2 mb-2'}
            >
              <Col md={6}>
                <div
                  className={'border-end'}
                  dangerouslySetInnerHTML={{
                    __html: page.text
                  }}
                />
              </Col>
              <Col md={6}>
                <div
                  style={{
                    maxHeight: '80vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    overflow: 'hidden',
                    padding: '1rem'
                  }}
                >
                  {page.img_light && !isDark && (
                    <Image
                      fluid
                      src={page.img_light}
                      style={{
                        maxWidth: '100%',
                        maxHeight: '70vh',
                        width: 'auto',
                        height: 'auto',
                        objectFit: 'contain'
                      }}
                    />
                  )}
                  {page.img_dark && isDark && (
                    <Image
                      fluid
                      src={page.img_dark}
                      style={{
                        maxWidth: '100%',
                        maxHeight: '70vh',
                        width: 'auto',
                        height: 'auto',
                        objectFit: 'contain'
                      }}
                    />
                  )}
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

ImageRightTextLeft.propTypes = {
  page: PropTypes.object.isRequired
};

ImageRightTextLeft.defaultTypes = {
  page: {}
};

export default ImageRightTextLeft;
